import React from "react"
import { graphql } from "gatsby"

import { useAnalytics } from "../hooks"

import { courseGroupType, courseBookingType } from "../types"
import { CourseGroupPage } from "../components"
import { courseTopicSlug } from "../utilities"

interface Props {
  data: { contentfulCourseTopics: courseGroupType } & {
    allContentfulCourseBookings: {
      edges: Array<{
        node: courseBookingType
      }>
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()
  analytics.track(["User", "Page View"], {
    page: courseTopicSlug(data.contentfulCourseTopics.slug),
  })

  const url =
    process.env.GATSBY_SITE_URL +
    courseTopicSlug(data.contentfulCourseTopics.slug)

  let bookingsMatchingCourses: { node: courseBookingType }[] = []

  data.allContentfulCourseBookings.edges.forEach((booking) => {
    booking.node.courses.forEach((course) => {
      if (
        data.contentfulCourseTopics.courses.some(
          (filteredCourse) =>
            filteredCourse.contentful_id === course.contentful_id
        )
      ) {
        if (
          bookingsMatchingCourses.some(
            (bookingsMatchingCourse) =>
              bookingsMatchingCourse.node.contentful_id ===
              booking.node.contentful_id
          )
        ) {
          // booking already in bookingsMatchingCourses
        } else {
          bookingsMatchingCourses.push(booking)
        }
      }
    })
  })

  return (
    <CourseGroupPage
      courseGroup={data.contentfulCourseTopics}
      bookings={bookingsMatchingCourses}
      url={url}
      variant="topic"
      viewCoursesAlternateUrl={
        data.contentfulCourseTopics.viewCoursesByLocationUrl
      }
    />
  )
}

export const pageQuery = graphql`
  query CourseTopic($contentful_id: String!) {
    contentfulCourseTopics(contentful_id: { eq: $contentful_id }) {
      contentful_id
      name
      slug
      image {
        gatsbyImageData
        title
      }
      description {
        raw
      }
      viewCoursesByLocationUrl
      hideProfessionFilters
      courses {
        contentful_id
        cpdTime
        hideCoursePrice
        skillLevel
        summary {
          summary
        }
        slug
        onlineOnly
        name
        partnerLogo {
          gatsbyImageData
          title
        }
        thinkificTraining {
          courseUrl
          contentful_id
          duration
        }
        topic {
          slug
        }
        professions
      }
      faqs {
        heading
        content {
          raw
        }
        contentful_id
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
    }
    allContentfulCourseBookings(
      sort: { order: ASC, fields: startDate }
      filter: { contentful_id: { ne: "3bBK7TnvlCI8JsnjF9Ma1m" } }
    ) {
      edges {
        node {
          contentful_id
          addressFull
          addressShort
          endDate
          startDate
          stripePriceId
          priceCurrency
          price
          showVat
          priceDisplayed
          discountedPrice
          location {
            contentful_id
            city {
              name
              contentful_id
              region {
                name
                contentful_id
              }
            }
          }
          courses {
            contentful_id
          }
        }
      }
    }
  }
`
